@import '../../App.less';

.Navbar{
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
  margin: 40px 0 20px 0;

  .city, 
  .selected{
    padding: 0;
    margin: 0;
    font-size: 36px;
    font-weight: 400;
    text-transform: uppercase;
  }
  .city{
    &:hover{
      cursor: pointer;
      color: #colors[theme_navyblue];
      font-weight: 700;
      transition: all 0.25s;
    }
  }

  .selected{
    color: #colors[hi_light];
    font-weight: 700;
  }
}
