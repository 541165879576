@import '../../App.less';

.Main{
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  min-height: 400px;
  margin: 20px auto;
  background: #colors[theme_white];
  border: 10px solid #colors[theme_white];
  border-radius: 10px;
  box-shadow: 0 0px 30px 10px rgba(0,0,0,0.1);
  list-style: none;
  padding: 0;

  li{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    min-width: 200px;
    background: #colors[theme_bg];

    .iconCont{
      color: #colors[theme_paleblue];
      text-shadow: 0px 0px 5px #colors[theme_navyblue];
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .date{
      font-weight: 400;
      margin: 0;
    }

    .textCont{
      h3,h4{
	margin: 0;
	text-transform: capitalize;
      }
      h4{
	font-weight: 400;
      }
    }

    &.today{
      width: 100%;
      padding: 60px 0;
      display: grid;
      grid-template-areas:
        "head   head"
        "image  text"
      ;
      gap: 30px;
      font-size: 40px;

      .date{
	grid-area: head;
	justify-self: center;
      }

      .iconCont{
	grid-area: image;
	font-size: 100px;
      }
      
      .textCont{
	grid-area: text;
      }

      .temp{
	font-size: 70px;
      }
    }

    &.nextDays{
      flex: 1;
      padding: 40px 0;
      display: flex;
      flex-flow: column;
      justify-content: center;
      text-align: center;
      gap: 30px;
      font-size: 30px;

      .date{
	font-weight: 400;
      }

      .iconCont{
	height: 100%;
	font-size: 50px;
      }
      
      .temp{
	font-size: 40px;
      }
    }
  }


}
